<template>
  <v-navigation-drawer
    :value="isDialogEdit"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? '90%' : '90%'"
    app
    @input="val => $emit('update:is-dialog-edit', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Edit New {{ Item.Id }} </span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-dialog-edit', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-stepper v-model="activeStep" class="custom-header">
          <!-- Header -->
          <v-stepper-header>
            <!-- Header: Step 1 -->
            <v-stepper-step :complete="activeStep > 1" step="1" :rules="[() => stepValidationResult[1]]">
              <div class="d-flex align-center">
                <span class="text--primary text-4xl font-weight-bold me-3">01</span>
                <div class="d-flex flex-column">
                  <span class="text--primary text-sm font-weight-semibold">Details</span>
                  <span class="text--secondary text-xs">Setup your item details</span>
                </div>
              </div>
            </v-stepper-step>

            <v-divider></v-divider>

            <!-- Header: Step 2 -->
            <v-stepper-step :complete="activeStep > 2" step="2" :rules="[() => stepValidationResult[2]]">
              <div class="d-flex align-center">
                <span class="text--primary text-4xl font-weight-bold me-3">02</span>
                <div class="d-flex flex-column">
                  <span class="text--primary text-sm font-weight-semibold">Addons Info</span>
                  <span class="text--secondary text-xs">Setup Addons Information</span>
                </div>
              </div>
            </v-stepper-step>

            <v-divider></v-divider>
          </v-stepper-header>

          <!-- Stepper Items -->
          <v-stepper-items>
            <!-- Stepper Content: Step 1 -->
            <v-stepper-content step="1">
              <v-card class="mb-8" height="200px">
                <v-form ref="step1Form" class="pb-6 pt-2">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="itemData.Name"
                        label="Name"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required, validators.usernameValidator(itemData.Name, 6)]"
                        placeholder="Name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        type="number"
                        v-model="itemData.Price"
                        label="Price"
                        prefix="$"
                        :value="itemData.Price"
                        @focus="$event.target.select()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-select
                        v-model="itemData.CategoryId"
                        :items="categoryOptions"
                        item-text="Name"
                        item-value="Id"
                        :rules="[v => !!v || 'Category is required']"
                        label="Category"
                        required
                        hide-details="auto"
                        class="mb-6"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-textarea
                        v-model="itemData.Description"
                        label="Description"
                        rows="1"
                        :prepend-icon="icons.mdiCommentOutline"
                      ></v-textarea> </v-col
                  ></v-row>
                </v-form>
              </v-card>
              <v-row>
                <v-col cols="2">
                  <v-btn color="error" class="me-2" @click="close()"> Cancel </v-btn>
                </v-col>
                <v-col cols="10">
                  <div class="d-flex justify-end">
                    <v-btn color="primary" class="me-2" @click="handleStepValidation(1)"> Next </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-stepper-content>

            <!-- Stepper Content: Step 2 -->
            <v-stepper-content step="2">
              <v-card class="mb-8">
                <v-form ref="step2Form" class="pb-6 pt-2 multi-col-validation">
                  <v-row>
                    <v-col cols="6">
                      <v-expansion-panels
                        :value="
                          addonsOptions.map((k, i) => {
                            if (k.disabled == true) return k.Id
                          })
                        "
                        multiple
                      >
                        <v-row style="display: contents" v-for="(item, i) in addonsOptions" :key="item.Id">
                          <v-col cols="1">
                            <v-checkbox
                              v-model="item.disabled"
                              @change="setAddon($event, item.Id, 'InAddons')"
                              :true-value="true"
                              :false-value="false"
                              color="success"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="11">
                            <v-expansion-panel :key="item.Id" :disabled="!item.disabled">
                              <v-expansion-panel-header>
                                {{ item.Name }}
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row>
                                  <v-col cols="4">
                                    <v-checkbox
                                      v-model="item.IsRequired"
                                      @change="setAddon($event, item.Id, 'IsRequired')"
                                      :true-value="true"
                                      :false-value="false"
                                      color="success"
                                      label="IsRequired"
                                      hide-details
                                    ></v-checkbox>
                                  </v-col>
                                  <v-col cols="4">
                                    <v-checkbox
                                      v-model="item.IsMulti"
                                      @change="setAddon($event, item.Id, 'IsMulti')"
                                      :true-value="true"
                                      :false-value="false"
                                      color="success"
                                      label="IsMulti"
                                      hide-details
                                    ></v-checkbox>
                                  </v-col>
                                  <v-col cols="4">
                                    <v-checkbox
                                      v-model="item.IsDependence"
                                      @change="setAddon($event, item.Id, 'IsDependence')"
                                      :true-value="true"
                                      :false-value="false"
                                      color="success"
                                      label="IsDependence"
                                      hide-details
                                    ></v-checkbox>
                                  </v-col>
                                </v-row>
                                <v-row v-for="option in item.Options" :key="option.Id">
                                  <v-col cols="6">
                                    <v-checkbox
                                      @change="setOption($event, item.Id, option, 'InOptions')"
                                      v-model="option.disabled"
                                      :true-value="true"
                                      :false-value="false"
                                      color="success"
                                      :label="`${option.Name.toString()}`"
                                      hide-details
                                    ></v-checkbox>
                                  </v-col>
                                  <v-col cols="6">
                                    <v-text-field
                                      type="Number"
                                      v-model="option.Price"
                                      @blur="setOption(option.Price, item.Id, option, 'Price')"
                                      :disabled="!option.disabled"
                                      label="Price"
                                      prefix="$"
                                      @focus="$event.target.select()"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content> </v-expansion-panel
                          ></v-col>
                        </v-row>
                      </v-expansion-panels>
                    </v-col>
                    <v-col cols="6">
                      <v-expansion-panels :key="expansionKey" :value="itemData.Addons.map((_k, i) => i)" multiple>
                        <v-expansion-panel v-for="(item, indx) in itemData.Addons" :key="indx">
                          <v-expansion-panel-header>
                             <v-row justify="start">
                              <v-col cols="1">
                                <div class="pt-3">
                                <v-chip
                                  class="ml-2"
                                  color="success"
                                  outlined
                                >{{item.Position}}</v-chip>
                              </div>
                              </v-col>
                              <v-col cols="2" >
                                <v-btn
                                 small
                                 icon
                                 fab
                                 class="mx-2 pt-5"
                                 color="secondary"
                                 :disabled="indx != 0 ? false : true"
                                 @click="addonUp(indx)"
                               >
                               <v-icon>{{icons.mdiArrowUp}} </v-icon>
                               </v-btn>
                               <v-btn
                                 class="mx-2 pt-5"
                                 fab
                                 small
                                 icon
                                 color="secondary"
                                 @click="addonDown(indx)"
                                 :disabled="indx != itemData.Addons.length-1 ? false : true"
                               >
                               <v-icon>{{icons.mdiArrowDown}}</v-icon>
                               </v-btn>
                              </v-col>
                              <v-col cols="7">
                            <v-row justify="center" class="pt-6 pr-4">
                              <h3 color="info">
                                <h4>
                                {{ item.Name }} {{ item.IsRequired ? ' - IsRequired' : '' }}
                            {{ item.IsMulti ? ' - IsMulti' : '' }} {{ item.IsDependence ? ' - IsDependence' : '' }}
                              </h4>

                          </h3>
                          </v-row>
                              </v-col>
                              <v-col cols="1"></v-col>
                              <v-col cols="1">
                                <div class="demo-space-x pt-1 pr-1">
                                 <v-btn icon color="error" @click="deleteAddon(item, indx)" >
                                   <v-icon small> {{ icons.mdiDeleteOutline }} </v-icon>
                                 </v-btn>
                               </div>
                              </v-col>
                             </v-row>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row v-for="option in item.OptionAddons" :key="option.Id" no-gutters>
                              <v-col cols="6" class="subtitle-1">
                                {{ option.Name }}
                              </v-col>
                              <v-col cols="6" class="subtitle-1"> <strong >{{ option.Price }}</strong></v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                      <div class="d-flex justify-end pt-8 pr-8 pl-8">
                <v-btn icon color="primary" @click="handleStepValidation(2, true, true)">
                  <v-icon x-large> {{ icons.mdiCheckboxMultipleMarkedCircleOutline }} </v-icon>
              </v-btn>
              </div>
                    </v-col>
                  </v-row></v-form
                >
              </v-card>

              <div class="d-flex justify-space-between">
                <v-btn outlined @click="handleStepValidation(2, false)"> Previous </v-btn>
                <v-btn color="primary" @click="handleStepValidation(2, true, true)"> Complete </v-btn>
              </div>
              <div class="mt-5">
                <v-row
                  ><v-col cols="12">
                    <v-btn color="error" class="me-2" @click="close()"> Cancel </v-btn>
                  </v-col></v-row
                >
              </div>
              <v-snackbar :light="$vuetify.theme.dark" v-model="isFormSubmittedSnackbarVisible">
                Form Submitted Successfully.
              </v-snackbar>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { GetAddonsByBranch } from '@core/api/Addons'
import { GetByBranchId } from '@core/api/Categories'
import { Edit, RemoveAddon } from '@core/api/Items'
import { mdiArrowDown, mdiArrowDownBold, mdiArrowUp, mdiArrowUpBold, mdiCheckboxMultipleMarkedCircleOutline, mdiClockTimeFourOutline, mdiClose, mdiDeleteOutline } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'

import {
alphaDashValidator,
alphaValidator,
confirmedValidator,
emailValidator,
passwordValidator,
priceValidator,
required,
usernameValidator
} from '@core/utils/validation'

export default {
  model: {
    prop: 'isDialogEdit',
    event: 'update:is-dialog-edit',
  },
  props: {
    isDialogEdit: {
      type: Boolean,
      required: true,
    },
    Item: { type: Object, required: true },
    BranchId: { type: Number, required: true },
  },
  watch: {
    Item(New) {
      //  console.log(New)
      this.itemData = New
      console.log(this.itemData)
      this.itemData.Addons.map((x, index) => {
        x.Position = index
        let Ao = this.addonsOptions.find(f => f.Name == x.Name)
        console.log('find addonsOptions', Ao)

        if (Ao) {
          Ao.disabled = true
          Ao.IsRequired = x.IsRequired
          Ao.IsMulti = x.IsMulti
          Ao.IsDependence = x.IsDependence
          Ao.Options.map((o, i) => {
            let Oa = x.OptionAddons.find(f => f.Name == o.Name)
            if (Oa != undefined) {
              o.disabled = true
            }
          })
        }
      })
      console.log('this.itemData', this.itemData)
    },
  },
  setup(props, { emit }) {
    const expansionKey = ref(true)
    onMounted(() => {
      GetByBranchId({ BranchId: props.BranchId }).then(res => {
        categoryOptions.value = res
      })
      GetAddonsByBranch({ BranchId: props.BranchId }).then(res => {
        if (res) {
          addonsOptions.value = res.map((x, index) => {
            return {
              Id: index,
              Name: x.Name,
              disabled: false,
              IsRequired: false,
              IsMulti: false,
              IsDependence: false,
              Description: x.Description,
              Options: x.Options.map((o, index) => {
                return {
                  Id: index,
                  Name: o.Name,
                  Price: o.Price,
                  disabled: false,
                }
              }),
            }
          })
        }
      })
    })
    // Data
    const blankItemData = {
      Id: undefined,
      Name: '',
      Type: '',
      Price: 0.0,
      Description: '',
      Status: 'active',
      BranchId: props.BranchId,
      CategoryId: null,
      Addons: [],
    }
    const deleteAddon = (item, indx) => {
      console.log('item', item)
      let addonId =item.Id
      RemoveAddon({AddonId : addonId}).then(res => {
      itemData.value.Addons.splice(indx, 1)
        console.log('deleteAddon', res)
      })
    }
    const itemData = ref(JSON.parse(JSON.stringify(blankItemData)))
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankItemData))
      step1Form.value.reset()
      step2Form.value.reset()
      activeStep.value = 1
      emit('update:is-dialog-edit', false)
    }
    const close = () => {
      resetItemData()
    }
    const onSubmit = () => {
      Edit(itemData.value).then(res => {
        if (res) {
          activeStep.value = 1
          emit('refetch-data')
          emit('update:is-dialog-edit', false)
          isFormSubmittedSnackbarVisible.value = true
          resetItemData()
        }
        resetItemData()
      }).catch
      isFormSubmittedSnackbarVisible.value = false
      resetItemData()
    }

    // Stepper's active step
    const activeStep = ref(1)
    const categoryOptions = ref([])
    const addonsOptions = ref([])

    // Form Submitted Snackbar
    const isFormSubmittedSnackbarVisible = ref(false)

    // Template refs
    const step1Form = ref(null)
    const step2Form = ref(null)

    // Step Validation (for `rules` prop of `v-stepper-step`)
    // It will be updated when step is validated
    const stepValidationResult = ref({
      1: true,
      2: true,
    })

    // Step Validation
    const handleStepValidation = (step, navigateNext = true, isFinalStep = false) => {
      console.log('vaaaaal', itemData.value)
      // Get Form templateRef of Step from argument
      const templateRef = (() => {
        if (step === 1) return step1Form
        if (step === 2) return step2Form

        return null
      })()

      // If no validate step templateRef is found just return
      if (!templateRef) return

      // If step is valid proceed to the next step
      const isStepValid = templateRef.value.validate()

      // Assign to stepValidationResult
      stepValidationResult.value[step] = isStepValid

      if (isStepValid) {
        if (navigateNext && !isFinalStep) activeStep.value += 1
        else if (!navigateNext) activeStep.value -= 1

        // Show Snackbar
        if (isFinalStep) {
          // ? Some Ajax request which saves form data in database
          itemData.value.Addons = itemData.value.Addons.map(x => {
            return {
              Id: undefined,
              Name: x.Name,
              IsRequired: x.IsRequired,
              IsMulti: x.IsMulti,
              IsDependence: x.IsDependence,
              Description: x.Description,
              Position: x.Position,
              OptionAddons: x.OptionAddons.map(o => {
                return {
                  Id: undefined,
                  Name: o.Name,
                  Price: o.Price,
                }
              }),
            }
          })
          onSubmit()
        }
      }
    }
    const addonDown = (i) => {
      console.log('down')
      let prev = itemData.value.Addons[i]
      let cur = itemData.value.Addons[i+1]
      prev.Position += 1
      cur.Position -= 1
      itemData.value.Addons[i] = cur
      itemData.value.Addons[i+1] = prev
      expansionKey.value = !expansionKey.value
    }
    const addonUp = (i) => {
      console.log('up')
      let prev = itemData.value.Addons[i]
      let cur = itemData.value.Addons[i-1]
      prev.Position -=1
      cur.Position +=1
      itemData.value.Addons[i] = cur
      itemData.value.Addons[i-1] = prev
      expansionKey.value = !expansionKey.value
    }
      itemData.value.Addons
    const setAddon = (value, Aid, type) => {
      let Ao = addonsOptions.value.find(f => f.Id == Aid)
      if (type === 'InAddons') {
        if (value == true) {
          itemData.value.Addons.push({
            Id: undefined,
            Name: Ao.Name,
            IsRequired: false,
            IsMulti: false,
            IsDependence: false,
            Description: '',
            Position: (itemData.value.Addons.length),
            OptionAddons: [],
          })
        } else
          itemData.value.Addons.splice(
            itemData.value.Addons.findIndex(f => f.Name == Ao.Name),
            1,
          )
      } else if (type === 'IsRequired') {
        Ao.IsDependence = false
        itemData.value.Addons.find(f => f.Name == Ao.Name).IsDependence = false

        itemData.value.Addons.find(f => f.Name == Ao.Name).IsRequired = value
      } else if (type === 'IsMulti') {
        Ao.IsDependence = false
        itemData.value.Addons.find(f => f.Name == Ao.Name).IsDependence = false

        itemData.value.Addons.find(f => f.Name == Ao.Name).IsMulti = value
      } else if (type === 'IsDependence') {
        Ao.IsRequired = false
        Ao.IsMulti = false
        itemData.value.Addons.find(f => f.Name == Ao.Name).IsMulti = false
        itemData.value.Addons.find(f => f.Name == Ao.Name).IsRequired = false

        itemData.value.Addons.find(f => f.Name == Ao.Name).IsDependence = value
      }
      console.log(Aid, type, itemData.value.Addons)
    }
    const setOption = (value, Aid, Op, type) => {
      let Ao = addonsOptions.value.find(f => f.Id == Aid)

      if (type === 'InOptions') {
        if (value == true) {
          itemData.value.Addons.find(f => f.Name == Ao.Name).OptionAddons.push({
            Id: undefined,
            Name: Op.Name,
            Price: Op.Price,
          })
        } else
          itemData.value.Addons.find(f => f.Name == Ao.Name).OptionAddons.splice(
            itemData.value.Addons.find(f => f.Name == Ao.Name).OptionAddons.findIndex(f => f.Name == Op.Name),
            1,
          )
      } else if (type === 'Price') {
        itemData.value.Addons.find(f => f.Name == Ao.Name).OptionAddons.find(f => f.Name == Op.Name).Price = value
      }
      console.log(value, Aid, Op, type, itemData.value.Addons)
    }
    return {
      deleteAddon,
      expansionKey,
      close,
      activeStep,
      itemData,
      categoryOptions,
      addonsOptions,
      setAddon,
      setOption,
      addonDown,
      addonUp,
      // Field Validators
      validators: {
        required,
        alphaDashValidator,
        emailValidator,
        passwordValidator,
        confirmedValidator,
        alphaValidator,
        usernameValidator,
        priceValidator,
      },

      // Step Validations
      stepValidationResult,
      handleStepValidation,
      icons: {
        mdiCheckboxMultipleMarkedCircleOutline ,
        mdiDeleteOutline,
        mdiClose,
        mdiClockTimeFourOutline,
        mdiArrowUpBold,
        mdiArrowDownBold,
        mdiArrowUp,
        mdiArrowDown,
      },
      // Template Refs
      step1Form,
      step2Form,

      // Snackbar
      isFormSubmittedSnackbarVisible,
    }
  },
}
</script>
