<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="10">
          <v-btn right color="primary" class="mb-4 me-3" @click.stop="isAddActive = !isAddActive">
            <v-icon size="18" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>Item</span>
          </v-btn></v-col
        >
        <v-col cols="2">
          <vc-menu-add-list :BranchId="parseInt($router.currentRoute.params.id)" />
        </v-col>
      </v-row>
      <item-add-new
        @refetch-data="fetchItems"
        :BranchId="parseInt($router.currentRoute.params.id)"
        v-model="isAddActive"
      />
      <item-delete @refetch-data="fetchItems" :Id="itemTemp.Id" v-model="isDialogDelete" />
      <item-edit
        @refetch-data="fetchItems"
        :BranchId="parseInt($router.currentRoute.params.id)"
        :Item="itemTemp"
        v-model="isDialogEdit"
      />

      <!-- Main Card -->
      <app-card-actions @refresh="fetchItems">
        <template slot="title"> Item List </template>
        <v-row class="px-2 ma-0">
          <v-col cols="12" sm="4">
            <!-- search -->
            <v-text-field v-model="searchQuery" placeholder="Search" outlined hide-details dense> </v-text-field>

            <v-spacer></v-spacer>
          </v-col>
        </v-row>
        <v-card-text>
          <v-data-table
            :headers="tableColumns"
            :items="ItemsListTable"
            item-key="Id"
            :items-per-page="10"
            :options.sync="options"
            :loading="loading"
            :expanded.sync="expanded"
            show-expand
          >
            <!-- Id -->
            <template #[`item.Id`]="{ item }" style="align: 'start'"> #{{ item.Id }} </template>
            <!-- name -->
            <template #[`item.Name`]="{ item }">
              <div class="d-flex align-center">
                <v-menu bottom min-width="300px" rounded offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn icon x-large v-on="on">
                      <v-avatar
                        :color="item.avatar ? '' : 'primary'"
                        :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                        size="32"
                      >
                        <vc-image
                          :width="32"
                          :height="32"
                          :object-id="item.Id"
                          table-name="Item"
                          :label-text="item.Name"
                        />
                      </v-avatar>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-list-item-content class="justify-center">
                      <vc-upload-image @refetch-data="fetchItems" :object-id="item.Id" table-name="Item" />
                    </v-list-item-content>
                  </v-card>
                </v-menu>
                <div class="d-flex flex-column ms-3">
                  {{ item.Name }}
                </div>
              </div>
            </template>
            <!-- Category -->
            <template #[`item.Category`]="{ item }" style="align: 'start'"> {{ item.Category.Name }} </template>
            <!-- Price -->
            <template #[`item.Price`]="{ item }" style="align: 'start'">
              {{ item.Price == 0 ? 'Price On  Selection' : `${item.Price.toFixed(3)} JD` }}
            </template>

            <!-- Status -->
            <template #[`item.Status`]="{ item }">
              <v-row>
                <v-col cols="3">
              <v-chip
                small
                :color="
                  $store.state.status['items'].find(obj => {
                    return obj.value == item.Status
                  }).variant || 'primary'
                "
                :class="`${
                  $store.state.status['items'].find(obj => {
                    return obj.value == item.Status
                  }).variant || 'primary'
                }--text`"
                class="v-chip-light-bg font-weight-semibold text-capitalize"
              >
                {{ item.Status }}
              </v-chip>
              </v-col>
              <v-col cols="6">
              <vc-change-status
                @refetch-data="fetchItems"
                :ObjectId="item.Id"
                TableName="items"
                :WithDialog="true"
                v-bind:Status="item.Status == 'active' ? 'inactive' : 'active'"
            />
            </v-col>
            </v-row>
            </template>
            <!-- Actions -->
            <template #[`item.Actions`]="{ item }">
              <div class="demo-space-x">
                <v-btn
                  v-if="$can('delete', 'Item')"
                  icon
                  color="error"
                  @click.stop=";(isDialogDelete = !isDialogDelete), (itemTemp = { ...item })"
                >
                  <v-icon small>
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </v-btn>
                <v-btn icon color="primary" @click.stop=";(isDialogEdit = !isDialogEdit), (itemTemp = { ...item })">
                  <v-icon small> {{ icons.mdiPencilOutline }} </v-icon>
                </v-btn>
              </div>
            </template>
            <!-- <template #[`item.Actions`]="{ item }">

          </template> -->
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length">
             <v-row v-if="true">
              <v-card outlined>
                <v-card-title>
                  <v-row>
                    <v-col :cols="12">
                      <v-data-table
                      :headers="expandedColumns"
                      :items="fil(item.Addons)"
                       item-key="Id"
                       :items-per-page="10"
                       >
                      <template #[`item.Id`]="{ item }" style="align: 'start'"> #{{ item.Id }} </template>
                      <template #[`item.Name`]="{ item }" style="align: 'start'"> {{ item.Name }} </template>
                      <template #[`item.Status`]="{ item }">
              <v-row>
                <v-col cols="3">
              <v-chip
                small
                :color="
                  $store.state.status['options'].find(obj => {
                    return obj.value == item.Status
                  }).variant || 'primary'
                "
                :class="`${
                  $store.state.status['options'].find(obj => {
                    return obj.value == item.Status
                  }).variant || 'primary'
                }--text`"
                class="v-chip-light-bg font-weight-semibold text-capitalize"
              >
                {{ item.Status }}
              </v-chip>
              </v-col>
              <v-col cols="6">
              <vc-change-status
                @refetch-data="fetchItems"
                :ObjectId="item.Id"
                TableName="options"
                :WithDialog="true"
                v-bind:Status="item.Status == 'active' ? 'inactive' : 'active'"
            />
            </v-col>
            </v-row>
            </template>
                       </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
              </v-row>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </app-card-actions>
    </v-col>
  </v-row>
</template>
<script>
import AppCardActions from '@/@core/components/app-card-actions/AppCardActions.vue'
import VcImage from '@/components/vc-avatar/VcImage.vue'
import VcUploadImage from '@/components/vc-input/Vc-UploadImage.vue'
import VcMenuAddList from '@/components/vc-menu-add-list/VcMenuAddList.vue'
import VcChangeStatus from '@/components/vc-status/vc-change-status.vue'
import { mdiDeleteOutline, mdiDotsVertical, mdiEyeOutline, mdiPencilOutline, mdiPlus, mdiTrendingUp } from '@mdi/js'
import { ref } from '@vue/composition-api'
import ItemAddNew from './ItemAddNew.vue'
import ItemDelete from './ItemDelete.vue'
import ItemEdit from './ItemEdit.vue'
import useItemList from './useItemList.js'
export default {
  components: {
    VcChangeStatus,
    ItemAddNew,
    VcMenuAddList,
    AppCardActions,
    ItemDelete,
    ItemEdit,
    VcImage,
    VcUploadImage,
  },
  setup() {
    const { ItemsListTable, expanded, tableColumns, searchQuery, totalItemsListTable, options, fetchItems, loading } =
      useItemList()
      const expandedColumns = [
    { text: 'Id', value: 'Id' },
    { text: 'Name', value: 'Name', sortable: false },
    { text: 'Price', value: 'Price', sortable: false },
    { text: 'Status', value: 'Status', sortable: false },

  ]
    const isAddActive = ref(false)
    const isDialogDelete = ref(false)
    const isDialogEdit = ref(false)
    const isdep = (item) => {
      let c = item.filter(f => f.IsDependence == true)
      console.log('ccccccc', c)
      return c
    }
  const fil = (item) => {
    let isdepvar = isdep(item)
    if(isdepvar.length > 0){

      let op = isdepvar.map(x => {
        return {
         OptionAddons: x.OptionAddons.map(l => {
           return {
            Id : l.Id,
            Name : l.Name,
            Price : l.Price,
            Status : l.Status,
           }
         })
        }
      })
      let obj = op[0].OptionAddons
      console.log('isdepvar', obj)
      return obj

    }
    // if ( isdepval.OptionAddons != undefined){
    //   let filtered = c.OptionAddons.slice()
    // console.log('filtered', filtered)
    // return filtered
    // }
    // else {
    //   return false
    // }

  }
    let itemTemp = ref({ Id: 0 })

    return {
      expandedColumns,
      fil,
      expanded,
      itemTemp,
      isAddActive,
      isDialogDelete,
      isDialogEdit,
      ItemsListTable,
      tableColumns,
      searchQuery,
      totalItemsListTable,
      options,
      fetchItems,
      isdep,
      loading,
      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.app-invoice-editable {
  .input-salesperson {
    width: 100px;
  }

  .select-invoice-to {
    width: 190px !important;
  }

  .header-inputs {
    width: 122px;
  }

  .add-products-form {
    .single-product-form {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
</style>
