import router from '@/router'
import { GetByBranchId } from '@core/api/Items'
import { ref, watch } from '@vue/composition-api'

export default function useAddonList() {
  const tableColumns = [
    { text: 'Id', value: 'Id' },
    { text: 'Name', value: 'Name', sortable: false },
    { text: 'Category', value: 'Category', sortable: false },
    { text: 'Price', value: 'Price', sortable: false },
    { text: 'Description', value: 'Description', sortable: false },
    { text: 'Status', value: 'Status', sortable: false },
    { text: 'Actions', value: 'Actions', sortable: false },
  ]

  const ItemsListTable = ref([])
  const searchQuery = ref('')
  const BranchId = ref(router.currentRoute.params.id)
  const statusFilter = ref(null)
  const expanded = ref([])
  const totalItemsListTable = ref(0)
  const options = ref({
    sortBy: ['Id'],
    sortDesc: [false],
  })
  const ItemTotalLocal = ref([])
  const loading = ref(false)
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const fetchItems = () => {
    GetByBranchId({ BranchId: BranchId.value, Any: searchQuery.value })
      .then(response => {
        // handle success

        ItemsListTable.value = response
        totalItemsListTable.value = ItemsListTable.lenght
        // ItemTotalLocal.value = ItemsListTable.lenght
        // remove loading state
        loading.value = false
      })
      .catch(error => {
        // remove loading state
        loading.value = false
        console.log(error)
      })
  }
  watch([searchQuery, statusFilter, options], () => {
    loading.value = true
    fetchItems()
  })
  return {
    expanded,
    ItemsListTable,
    tableColumns,
    searchQuery,
    BranchId,
    statusFilter,
    totalItemsListTable,
    options,
    ItemTotalLocal,
    fetchItems,
    loading,
  }
}
